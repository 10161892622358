import { default as aboutqNk3HSzUMDMeta } from "/usr/src/nuxt-app/pages/about.vue?macro=true";
import { default as addMPs3S832oSMeta } from "/usr/src/nuxt-app/pages/agents/add.vue?macro=true";
import { default as _91id_937NrBlvu38rMeta } from "/usr/src/nuxt-app/pages/agents/edit/[id].vue?macro=true";
import { default as indexiytrhVLakAMeta } from "/usr/src/nuxt-app/pages/agents/index.vue?macro=true";
import { default as _91id_93YbqFo1DGD1Meta } from "/usr/src/nuxt-app/pages/claim/[id].vue?macro=true";
import { default as failurev6XaHtTBWVMeta } from "/usr/src/nuxt-app/pages/claim/failure.vue?macro=true";
import { default as successlIPXfpIdVPMeta } from "/usr/src/nuxt-app/pages/claim/success.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as _91id_93PUh53UccUTMeta } from "/usr/src/nuxt-app/pages/leads/[id].vue?macro=true";
import { default as adds8iDSP4myYMeta } from "/usr/src/nuxt-app/pages/leads/add.vue?macro=true";
import { default as _91id_93QOZdsIheWUMeta } from "/usr/src/nuxt-app/pages/leads/edit/[id].vue?macro=true";
import { default as index6gMUc1WfHDMeta } from "/usr/src/nuxt-app/pages/leads/index.vue?macro=true";
import { default as blastQ0EPwoMqNPMeta } from "/usr/src/nuxt-app/pages/leads/post/blast.vue?macro=true";
import { default as indexjC8QmFRisrMeta } from "/usr/src/nuxt-app/pages/leads/post/index.vue?macro=true";
import { default as multipleOjzq2HuIiPMeta } from "/usr/src/nuxt-app/pages/leads/post/multiple.vue?macro=true";
import { default as notify9FFmj0jXaLMeta } from "/usr/src/nuxt-app/pages/leads/post/notify.vue?macro=true";
import { default as sendG5UpXx5c5bMeta } from "/usr/src/nuxt-app/pages/leads/post/send.vue?macro=true";
import { default as singlewNA1pT2LoGMeta } from "/usr/src/nuxt-app/pages/leads/post/single.vue?macro=true";
import { default as settingsOD0t6uz15aMeta } from "/usr/src/nuxt-app/pages/settings.vue?macro=true";
export default [
  {
    name: aboutqNk3HSzUMDMeta?.name ?? "about",
    path: aboutqNk3HSzUMDMeta?.path ?? "/about",
    meta: aboutqNk3HSzUMDMeta || {},
    alias: aboutqNk3HSzUMDMeta?.alias || [],
    redirect: aboutqNk3HSzUMDMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: addMPs3S832oSMeta?.name ?? "agents-add",
    path: addMPs3S832oSMeta?.path ?? "/agents/add",
    meta: addMPs3S832oSMeta || {},
    alias: addMPs3S832oSMeta?.alias || [],
    redirect: addMPs3S832oSMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/agents/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_937NrBlvu38rMeta?.name ?? "agents-edit-id",
    path: _91id_937NrBlvu38rMeta?.path ?? "/agents/edit/:id()",
    meta: _91id_937NrBlvu38rMeta || {},
    alias: _91id_937NrBlvu38rMeta?.alias || [],
    redirect: _91id_937NrBlvu38rMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/agents/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiytrhVLakAMeta?.name ?? "agents",
    path: indexiytrhVLakAMeta?.path ?? "/agents",
    meta: indexiytrhVLakAMeta || {},
    alias: indexiytrhVLakAMeta?.alias || [],
    redirect: indexiytrhVLakAMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/agents/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93YbqFo1DGD1Meta?.name ?? "claim-id",
    path: _91id_93YbqFo1DGD1Meta?.path ?? "/claim/:id()",
    meta: _91id_93YbqFo1DGD1Meta || {},
    alias: _91id_93YbqFo1DGD1Meta?.alias || [],
    redirect: _91id_93YbqFo1DGD1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/claim/[id].vue").then(m => m.default || m)
  },
  {
    name: failurev6XaHtTBWVMeta?.name ?? "claim-failure",
    path: failurev6XaHtTBWVMeta?.path ?? "/claim/failure",
    meta: failurev6XaHtTBWVMeta || {},
    alias: failurev6XaHtTBWVMeta?.alias || [],
    redirect: failurev6XaHtTBWVMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/claim/failure.vue").then(m => m.default || m)
  },
  {
    name: successlIPXfpIdVPMeta?.name ?? "claim-success",
    path: successlIPXfpIdVPMeta?.path ?? "/claim/success",
    meta: successlIPXfpIdVPMeta || {},
    alias: successlIPXfpIdVPMeta?.alias || [],
    redirect: successlIPXfpIdVPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/claim/success.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PUh53UccUTMeta?.name ?? "leads-id",
    path: _91id_93PUh53UccUTMeta?.path ?? "/leads/:id()",
    meta: _91id_93PUh53UccUTMeta || {},
    alias: _91id_93PUh53UccUTMeta?.alias || [],
    redirect: _91id_93PUh53UccUTMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/[id].vue").then(m => m.default || m)
  },
  {
    name: adds8iDSP4myYMeta?.name ?? "leads-add",
    path: adds8iDSP4myYMeta?.path ?? "/leads/add",
    meta: adds8iDSP4myYMeta || {},
    alias: adds8iDSP4myYMeta?.alias || [],
    redirect: adds8iDSP4myYMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93QOZdsIheWUMeta?.name ?? "leads-edit-id",
    path: _91id_93QOZdsIheWUMeta?.path ?? "/leads/edit/:id()",
    meta: _91id_93QOZdsIheWUMeta || {},
    alias: _91id_93QOZdsIheWUMeta?.alias || [],
    redirect: _91id_93QOZdsIheWUMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: index6gMUc1WfHDMeta?.name ?? "leads",
    path: index6gMUc1WfHDMeta?.path ?? "/leads",
    meta: index6gMUc1WfHDMeta || {},
    alias: index6gMUc1WfHDMeta?.alias || [],
    redirect: index6gMUc1WfHDMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/index.vue").then(m => m.default || m)
  },
  {
    name: blastQ0EPwoMqNPMeta?.name ?? "leads-post-blast",
    path: blastQ0EPwoMqNPMeta?.path ?? "/leads/post/blast",
    meta: blastQ0EPwoMqNPMeta || {},
    alias: blastQ0EPwoMqNPMeta?.alias || [],
    redirect: blastQ0EPwoMqNPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/post/blast.vue").then(m => m.default || m)
  },
  {
    name: indexjC8QmFRisrMeta?.name ?? "leads-post",
    path: indexjC8QmFRisrMeta?.path ?? "/leads/post",
    meta: indexjC8QmFRisrMeta || {},
    alias: indexjC8QmFRisrMeta?.alias || [],
    redirect: indexjC8QmFRisrMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/post/index.vue").then(m => m.default || m)
  },
  {
    name: multipleOjzq2HuIiPMeta?.name ?? "leads-post-multiple",
    path: multipleOjzq2HuIiPMeta?.path ?? "/leads/post/multiple",
    meta: multipleOjzq2HuIiPMeta || {},
    alias: multipleOjzq2HuIiPMeta?.alias || [],
    redirect: multipleOjzq2HuIiPMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/post/multiple.vue").then(m => m.default || m)
  },
  {
    name: notify9FFmj0jXaLMeta?.name ?? "leads-post-notify",
    path: notify9FFmj0jXaLMeta?.path ?? "/leads/post/notify",
    meta: notify9FFmj0jXaLMeta || {},
    alias: notify9FFmj0jXaLMeta?.alias || [],
    redirect: notify9FFmj0jXaLMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/post/notify.vue").then(m => m.default || m)
  },
  {
    name: sendG5UpXx5c5bMeta?.name ?? "leads-post-send",
    path: sendG5UpXx5c5bMeta?.path ?? "/leads/post/send",
    meta: sendG5UpXx5c5bMeta || {},
    alias: sendG5UpXx5c5bMeta?.alias || [],
    redirect: sendG5UpXx5c5bMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/post/send.vue").then(m => m.default || m)
  },
  {
    name: singlewNA1pT2LoGMeta?.name ?? "leads-post-single",
    path: singlewNA1pT2LoGMeta?.path ?? "/leads/post/single",
    meta: singlewNA1pT2LoGMeta || {},
    alias: singlewNA1pT2LoGMeta?.alias || [],
    redirect: singlewNA1pT2LoGMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/leads/post/single.vue").then(m => m.default || m)
  },
  {
    name: settingsOD0t6uz15aMeta?.name ?? "settings",
    path: settingsOD0t6uz15aMeta?.path ?? "/settings",
    meta: settingsOD0t6uz15aMeta || {},
    alias: settingsOD0t6uz15aMeta?.alias || [],
    redirect: settingsOD0t6uz15aMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/settings.vue").then(m => m.default || m)
  }
]