import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const config = useRuntimeConfig()
  // const sentry = config.public.client.sentry

  if (!config.public.clientSentryDsn) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
  }

  if (!config.public.env) {
    console.warn('Environment not set, skipping Sentry initialization')
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.clientSentryDsn,
    environment: config.public.env,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'http://agentsmithapp.com'],

    replaysSessionSampleRate: 1.0, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  })
})

